<template>
  <div id="RejectionBlock">
    <div>
      <h2>Уважаемый клиент!</h2>
      <p> К сожалению мы не можем одобрить сумму выше 30 000 тысяч рублей<br> по данному адресу</p>
      <p style="margin-bottom: 0"> Вы можете воспользоваться нашим займом до 30 тысяч рублей </p>
      <div>
        <p> Со ставкой </p>
        <p>0%</p>
      </div>
    </div>

    <button v-on:click="toPdl">Подать заявку</button>

  </div>
</template>

<script>

export default {
  name: "screenRejection",
  methods:{
    async toPdl(){
      this.$store.dispatch('repeatLoan/changeCurrentScreen', 'calc');
    }
  },
}
</script>

<style scoped>
h2 { padding: 10vw 0; color: #0D5740;}
p { margin-bottom: 9.12vw; color: #090A09 }
a { text-decoration-line: underline }
button { width: 58.82vw; border-radius: 10px;}
#RejectionBlock { display: flex; flex-direction: column; align-items: center; }
#RejectionBlock > div { padding: 0; margin-bottom: 8.24vw; display: flex; flex-direction: column; align-items: center }
#RejectionBlock > div > div { display: flex; align-items: center }
#RejectionBlock > div > div > p:last-child { background: #4AAF3D; color: #FFFFFF; padding: 0.88vw 1.76vw; font-family: 'Lato-Bold'; border-radius: 10px; margin-left: 0.88vw }
@media (min-width: 760px) {
  h2 { padding: 3.47vw 0 }
  p { margin-bottom: 3.23vw; text-align: center }
  a { }
  button { width: 16.13vw }
  #RejectionBlock { padding-top: 7.6vw }
  #RejectionBlock > div { padding: 0 4.35vw 2.42vw; border-radius: 10px; box-shadow: 0 0.5vw 0.5vw rgba(0, 0, 0, 0.25); margin-bottom: 5.81vw; display: flex; flex-direction: column; align-items: center; background: #FFFBFB; }
  #RejectionBlock > div > div > p {margin-bottom: 3.23vw }
  #RejectionBlock > div > div > p:last-child {padding: 0.24vw 0.48vw; margin-left: 0.88vw; }
}
@media (min-width: 1240px) {
  h2 { padding: 41px 0 47px 0 }
  #anketaSteps > div > h2 {}
  p { margin-bottom: 40px }
  a { }
  #RejectionBlock {padding-top: 97px }
  button { width: 200px }
  #RejectionBlock > div { padding: 0 54px 10px 54px; margin-bottom: 78px; box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25) }
}
</style>
